<template>
    <div class="card mb-1 mb-xl-10">
        <div class="card-body pb-1">
            <div class="card-title d-flex justify-content-end mb-2">
                <div class="d-flex justify-content-end">
                    <button
                        v-show="getShowInvoiceButton"
                        type="button"
                        class="btn btn-sm  btn-primary btn-active-light-primary"
                        @click="handleViewRecib"
                    >
                        <i class="bi bi-receipt-cutoff text-center fs-2"></i>
                        {{ 'Ver reporte de venta'}}
                    </button>
                </div>
            </div>

            <div class="card-title d-flex justify-content-between">
                <div class="d-flex justify-content-start">
                    <h3 class="fw-bolder ml-2">{{ $t('LABELS.PURCHASE_TOTAL') }}: {{ formatNumeric(purchaseTotal) }}</h3>
                </div>
                <div class="d-flex justify-content-start">
                    <h3 class="fw-bolder ml-2">{{ $t('LABELS.PENDING_AMOUNT') }}: {{ formatNumeric(getPendingAmount) }}</h3>
                </div>
            </div>
            <Form as="el-form" :validation-schema="validationSchema" :initial-values="initialValues" @submit="onSubmit" ref="form">
                <div class="row">
                    <div class="col">
                        <label class="required fs-6 fw-bold mb-2">Tipo de Pago</label>
                        <SelectOption
                            class="mw-control"
                            name="paymentTypeId"
                            :options="comboPaymentTypes"
                            :disabled="disableControls"
                            @change="handlerChangePayment"
                            :fullWidth="true"
                        />
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-6">
                        <label class="required fs-6 fw-bold mb-2">Importe</label>
                        <Field  name="amount" v-slot="{value, field, errorMessage}">
                            <el-form-item :error="errorMessage">
                                <el-input
                                    class="mw-control"
                                    v-bind="field"
                                    type="text"
                                    placeholder="Importe"
                                    :validate-event="false"
                                    :disabled="disableControls"
                                    :model-value="value"
                                    @input="handlerchange"
                                />
                            </el-form-item>
                        </Field>
                    </div>
                    <div class="col-6">
                        <label class="required fs-6 fw-bold mb-2">Tipo de Moneda</label>
                        <SelectOption
                            class="mw-control"
                            name="currencyId"
                            :default="true"
                            :options="currencyTypes"
                            :disabled="disableControls"
                        />
                    </div>
                </div>

                <div class="row pt-5">
                    <div class="col-6" v-show="showFliedReferences">
                        <label class="required fs-6 fw-bold mb-2">Referencia</label>
                        <Field  name="ChargeReference" v-slot="{value, field, errorMessage}">
                            <el-form-item :error="errorMessage">
                                <el-input
                                    class="mw-control"
                                    v-bind="field"
                                    type="text"
                                    placeholder="Referencia"
                                    :validate-event="false"
                                    :disabled="disableControls"
                                    :model-value="value"
                                />
                            </el-form-item>
                        </Field>
                    </div>
                    <div class="col-6">
                        <label class="fs-6 fw-bold mb-2">Cambio</label>
                        <el-form-item :error="errorMessage">
                            <el-input
                                class="mw-control"
                                v-model="change"
                                type="text"
                                placeholder="Cambio"
                                :validate-event="false"
                                :disabled="true"
                            />
                        </el-form-item>
                    </div>
                </div>

                <div class="row pt-5 pb-5">
                    <div class="col-6">
                        <button
                            :disabled="disableControls"
                            type="button"
                            class="btn btn-sm  btn-primary btn-active-light-primary"
                            @click="onClickPaymentApply"
                            style="width: 186px; height: 50px; padding-bottom: 12px;"
                        >
                            <i class="bi bi-coin text-center fs-2"></i>
                            {{$t("BUTTONS.APPLY") }}
                        </button>
                    </div>
                    <div class="col-6">
                        <button
                            v-show="getShowInvoiceButton"
                            type="button"
                            class="btn btn-sm  btn-primary btn-active-light-primary"
                            @click="onClickInvoice"
                            style="width: 186px; height: 50px; padding-bottom: 12px;"
                        >
                            <i class="bi bi-coin text-center fs-2"></i>
                            {{$t("BUTTONS.INVOICE") }}
                        </button>
                    </div>
                </div>
                <button hidden type="submit" ref="submitBtn"></button>
            </Form>
        </div>
    </div>

    <PDFViewer :base64="pdf" :titulo="'Recibo de Venta'" :fileName="'ReciboVenta'" ></PDFViewer>
    <button
        hidden
        ref="openPDF"
        class="btn btn-primary er fs-6 "
        data-bs-toggle="modal"
        data-bs-target="#viewPDF"
    >
        {{ $t("BUTTOMS.NEW_SETTLEMENT") }}
    </button>
</template>

<script>
import {computed, defineComponent, ref, toRefs, onBeforeMount, watch, onMounted} from 'vue';
import {formatNumeric} from "@/core/shared/functions/Formatter";
import {Form, Field} from 'vee-validate';
//import RowForm from "../../components/c-form-elements/RowForm.vue";
import * as Validations from "yup";
import SelectOption from "../../components/c-form-elements/SelectOption.vue";
import ApiService from "@/core/services/ApiService";
import SwalMessageService from "@/core/services/SwalMessageService";
import {getMessageError} from '@/core/helpers/messageFromPulmeros';
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import PDFViewer from "@/components/modals/general/PDFViewer.vue";
import NewSaleModule from "@/store/modules/sales/modules/new-sales";
import {getModule} from "vuex-module-decorators";
import toCurrency from "@/core/filters/ToCurrency"

export default defineComponent({
    name: "payments-box",
    components: {
        Form
        , Field
        //, RowForm
        , SelectOption
        , PDFViewer
    },
    props: {
        purchase: {
            type: Object
        }
    },
    setup(props, context) {
        //VARAIBLES Y CONSTANTES
        let {purchase} = toRefs(props);
        const openPDF = ref(HTMLButtonElement);
        const moduleNewSale = getModule(NewSaleModule);
        let form = ref();
        let submitBtn = ref(null);
        let saleId = ref("");
        let change = ref(0)
        let showFliedReferences = ref(true);
        const validationSchema = Validations.object().shape({
            amount: Validations.number().required().min(1, "El valor mínimo debe ser 1.").typeError("Sólo se adminiten valores numéricos.").label("amount"),
            paymentTypeId: Validations.string().required().label("paymentTypeId"),
            currencyId: Validations.string().required().label("currencyId"),
            ChargeReference: Validations.string().label("ChargeReference"),
        });
        let paymentTypes = ref([]);
        let currency = ref([]);
        let amountPaided = ref(0);
        const messageService = new SwalMessageService();
        let initialOutStandingBalance = ref(0);
        let showInvoiceButton = ref(false);
        const applyElectronicPayment = ref(false);
        const electronicPaymentIds = ref(["04 TARJETA DE CREDITO", "03 TRANSFERENCIA ELECTRONICA", "28 TARJETA DEBITO"]);//por mientras esta determinado por los nombre pero debe haber un lugar donde recuperar los pagos electronicos(ids)

        const initialValues = computed(() => {
            let item = {};
            if (currency.value.length == 1) {
                item.currencyId = currency.value[0].id;
            }

            const row = comboPaymentTypes.value.find(ir => ir.default === true);

            if (row) {
                item.paymentTypeId = row.id;
                handlerChangePayment(row.id)
            }

            return item;
        });

        //HOOKS
        onBeforeMount(async () => {
            await getComboOptions("WayToPay", paymentTypes);
            await getComboOptions("Currency", currency);
        });

        //WATCHS
        watch(purchase, (newValue) => {
            ApiService.get(`Charges/${purchase.value.id}`).then(res => {

                if (res.status == 200) {
                    initialOutStandingBalance.value = res.data.outstandingBalance;

                    if (res.data.outstandingBalance == 0) {
                        if(!purchase.value.isBilled){
                            showInvoiceButton.value = true;
                        }
                        saleId.value = res.data.saleId;
                    }
                }
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        });

        //COMPUTEDS
        const purchaseTotal = computed(() => {
            let total = purchase.value ? purchase.value.total : 0;
            return total;
        });
        const comboPaymentTypes = computed(() => paymentTypes.value);
        const currencyTypes = computed(() => currency.value);
        const disableControls = computed(() => {
            return purchase.value ? purchase.value.isPayed : true;
        });
        const getPendingAmount = computed(() => initialOutStandingBalance.value);
        const getShowInvoiceButton = computed(() => showInvoiceButton.value);

        //FUNCTIONS
        const handlerchange = (date) => {
            change.value = 0;
            let cambios = date - initialOutStandingBalance.value;

            if (cambios > 0) {
                change.value = toCurrency(parseFloat(cambios.toFixed(2)));
            }
        }

        const getComboOptions = async (resource, container, parentId = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo`;
            ApiService.get(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const onClickPaymentApply = () => {
            if (submitBtn.value) {
                submitBtn.value.click()
            }
        }

        const handlerChangePayment = (id) => {
            let isCaptureReference = true;
            const row = comboPaymentTypes.value.find(ir => ir.id === id);

            if (row) {
                isCaptureReference = !row.default;

                if (isCaptureReference) {
                    showFliedReferences.value = true;
                    validationSchema.fields.ChargeReference = Validations.string().required().label("ChargeReference");
                } else {
                    showFliedReferences.value = false;
                    validationSchema.fields.ChargeReference = Validations.string().label("ChargeReference");
                }

                applyElectronicPayment.value = electronicPaymentIds.value.includes(row.name);
            } else {
                applyElectronicPayment.value = false;
            }
        }

        const onSubmit = async (dataForm) => {
            dataForm.chargeId = '20e9ef35-c25a-465d-9d40-96f9fb9a80e7';
            dataForm.cashierId = '30e9ef35-c25a-465d-9d40-96f9fb9a80e7';
            dataForm.cashRegisterId = 'cbbc68d2-acbc-43ef-9b79-d318f6e7b94b';
            dataForm.reference = dataForm.ChargeReference = !undefined ? dataForm.ChargeReference : "";

            ApiService.get(`Charges/${purchase.value.id}`).then(res => {
                if (res.status == 200) {
                    if (parseFloat(dataForm.amount) > res.data.outstandingBalance) {
                        // change.value = parseFloat(dataForm.amount) - res.data.outstandingBalance;
                        dataForm.amount = res.data.outstandingBalance;
                        amountPaided.value = res.data.total;
                    } else {
                        change.value = 0;
                        amountPaided.value += parseFloat(dataForm.amount);
                    }
                    applyPayment(dataForm);
                }
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });

        }

        const applyPayment = (dataForm) => {
            ApiService.post(`Charges/${purchase.value.id}`, dataForm).then(res => {
                if (res.data.id) {
                    ApiService.get(`Charges/${purchase.value.id}`).then(res2 => {
                        if (res2.status == 200) {
                            initialOutStandingBalance.value = res2.data.outstandingBalance;
                            if (res2.data.outstandingBalance == 0) {
                                showInvoiceButton.value = true;
                                saleId.value = res2.data.saleId;
                                ApiService.put(`Sales/PaySale`, {id: purchase.value.id}).then(resPay => {
                                    if (resPay.status == 204) {
                                        purchase.value.isPayed = true;
                                        Swal.fire({
                                            title: `El pago se completó de manera exitosa. ¿Desea generar la factura?`,
                                            icon: "warning",
                                            showDenyButton: false,
                                            showCancelButton: true,
                                            confirmButtonText: 'Sí',
                                            cancelButtonText: 'No',
                                            denyButtonText: 'No',
                                        }).then((result) => {
                                                if (result.isConfirmed) {
                                                    context.emit("onGenerateInvoice");
                                                } else if (applyElectronicPayment.value) {
                                                    //si selecciono que no hay que verificar si es
                                                    //pago con tarjeta(credito debito) o transferencia
                                                    ApiService.get("Invoice", `BillDefault?id=${saleId.value}`)
                                                }
                                            }
                                        );
                                    }
                                });
                            } else {
                                messageService.success("El pago se efectuó de manera exitosa.");
                            }
                        }
                    }).catch(reject => {
                        getMessageError(reject, messageService, "MessageError");
                    });

                    form.value.resetForm();
                }
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const handleViewRecib = () => {
            let actionModule;

            actionModule = moduleNewSale.GET_RECEIP_VIEW(
                saleId.value,
            );

            actionModule.then((isCorrect2) => {
                if (isCorrect2) {
                    if (openPDF.value) {
                        (openPDF.value).click();
                    }
                }
            });

        }

        const resetValues = (purchaseId) => {
            form.value.resetForm();
            amountPaided.value = 0;
            change.value = 0;
            ApiService.get(`Charges/${purchaseId}`).then(res => {
                if (res.status == 200) {
                    amountPaided.value = res.data.total - res.data.outstandingBalance;
                }
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const onClickInvoice = () => {
            context.emit("onGenerateInvoice");
            showInvoiceButton.value = false;
        }

        const pdf = computed(() => moduleNewSale.getbase64PDF);

        return {
            //Variables y constantes
            change
            , form
            , submitBtn
            //Computeds
            , disableControls
            , purchaseTotal
            , validationSchema
            , comboPaymentTypes
            , currencyTypes
            , getPendingAmount
            , initialValues
            , getShowInvoiceButton
            , showFliedReferences
            , handleViewRecib
            , moduleNewSale
            , openPDF
            , pdf

            //Functions
            , handlerchange
            , handlerChangePayment
            , formatNumeric
            , onClickPaymentApply
            , onSubmit
            , resetValues
            , onClickInvoice
            , toCurrency
        }
    },
})
</script>

<style>
.mw-control {
    min-width: auto;
    //width: auto;
}
</style>

<i18n>
{
    "es": {
        "LABELS": {
            "PURCHASE_TOTAL": "Total Compra",
            "PENDING_AMOUNT": "Pendiente"
        },
        "BUTTONS": {
            "APPLY": "Aplicar Pago",
            "INVOICE": "Facturar"
        }
    }
}
</i18n>